<div class="flex">
  <!-- Outfits -->
  <div class="item-icon category" *ngIf="_showTypes['Outfit']" [class.highlight]="type === 'Outfit'"
  [ngbTooltip]="'Outfits'" container="body"
    (click)="changeType('Outfit')">
    <mat-icon svgIcon="outfit"></mat-icon>
  </div>
  <!-- Shoes -->
  <div class="item-icon category" *ngIf="_showTypes['Shoes']" [class.highlight]="type === 'Shoes'"
  [ngbTooltip]="'Shoes'" container="body"
    (click)="changeType('Shoes')">
    <mat-icon svgIcon="shoes"></mat-icon>
  </div>
  <!-- Masks -->
  <div class="item-icon category" *ngIf="_showTypes['Mask']" [class.highlight]="type === 'Mask'"
  [ngbTooltip]="'Masks'" container="body"
    (click)="changeType('Mask')">
    <mat-icon svgIcon="mask"></mat-icon>
  </div>
  <!-- Face Accessories -->
  <div class="item-icon category" *ngIf="_showTypes['FaceAccessory']" [class.highlight]="type === 'FaceAccessory'"
  [ngbTooltip]="'Face accessories'" container="body"
    (click)="changeType('FaceAccessory')">
    <mat-icon svgIcon="face-acc"></mat-icon>
  </div>
  <!-- Necklaces -->
  <div class="item-icon category" *ngIf="_showTypes['Necklace']" [class.highlight]="type === 'Necklace'"
  [ngbTooltip]="'Necklaces'" container="body"
    (click)="changeType('Necklace')">
    <mat-icon svgIcon="necklace"></mat-icon>
  </div>
  <!-- Hair -->
  <div class="item-icon category" *ngIf="_showTypes['Hair']" [class.highlight]="type === 'Hair'"
  [ngbTooltip]="'Hair'" container="body"
    (click)="changeType('Hair')">
    <mat-icon svgIcon="hair"></mat-icon>
  </div>
  <!-- Hats -->
  <div class="item-icon category" *ngIf="_showTypes['Hat']" [class.highlight]="type === 'Hat'"
  [ngbTooltip]="'Hats'" container="body"
    (click)="changeType('Hat')">
    <mat-icon svgIcon="hat"></mat-icon>
  </div>
  <!-- Capes -->
  <div class="item-icon category" *ngIf="_showTypes['Cape']" [class.highlight]="type === 'Cape'"
  [ngbTooltip]="'Capes'" container="body"
    (click)="changeType('Cape')">
    <mat-icon svgIcon="cape"></mat-icon>
  </div>
  <!-- Held -->
  <div class="item-icon category" *ngIf="_showTypes['Held']" [class.highlight]="type === 'Held'"
  [ngbTooltip]="'Held props'" container="body"
    (click)="changeType('Held')">
    <mat-icon svgIcon="held"></mat-icon>
  </div>
  <!-- Prop -->
  <div class="item-icon category" *ngIf="_showTypes['Furniture']" [class.highlight]="type === 'Furniture'"
  [ngbTooltip]="'Furniture'" container="body"
    (click)="changeType('Furniture')">
    <mat-icon svgIcon="shelf"></mat-icon>
  </div>
  <!-- Prop -->
  <div class="item-icon category" *ngIf="_showTypes['Prop']" [class.highlight]="type === 'Prop'"
  [ngbTooltip]="'Props'" container="body"
    (click)="changeType('Prop')">
    <mat-icon svgIcon="cup"></mat-icon>
  </div>
  <!-- Emote -->
  <div class="item-icon category" *ngIf="_showTypes['Emote']" [class.highlight]="type === 'Emote'"
  [ngbTooltip]="'Emotes'" container="body"
    (click)="changeType('Emote')">
    <mat-icon svgIcon="emote"></mat-icon>
  </div>
  <!-- Stance -->
  <div class="item-icon category" *ngIf="_showTypes['Stance']" [class.highlight]="type === 'Stance'"
  [ngbTooltip]="'Stances'" container="body"
    (click)="changeType('Stance')">
    <mat-icon svgIcon="stance"></mat-icon>
  </div>
  <!-- Call -->
  <div class="item-icon category" *ngIf="_showTypes['Call']" [class.highlight]="type === 'Call'"
  [ngbTooltip]="'Calls'" container="body"
    (click)="changeType('Call')">
    <mat-icon svgIcon="call"></mat-icon>
  </div>
  <!-- Music -->
  <div class="item-icon category" *ngIf="_showTypes['Music']" [class.highlight]="type === 'Music'"
    [ngbTooltip]="'Music'" container="body"
    (click)="changeType('Music')">
    <mat-icon svgIcon="sheet"></mat-icon>
  </div>
</div>
